import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

let contentSlider;

const initializeSliders = () => {
  const isDesktop = window.innerWidth >= 866;

  contentSlider = document.querySelector('.m-content-slider')
    ? new Splide('.splide.m-content-slider', {
      type: 'loop',
      drag: 'free',
      snap: false,
      gap: '4.167vw',
    }).mount(isDesktop ? { AutoScroll } : {})
    : null;
};

window.addEventListener('load', initializeSliders);

let previousIsDesktop = window.innerWidth >= 866;

window.addEventListener('resize', () => {
  const isDesktop = window.innerWidth >= 866;
  if (isDesktop !== previousIsDesktop) {
    initializeSliders();
    previousIsDesktop = isDesktop;
  }
});
